

.sponser{
   padding-top: 3rem;
   padding-bottom: 3rem;
}

.sponserimages{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 0rem 2rem;
}

.sponserimages img{
  cursor: pointer;
}