.sliderSection{
    padding-top: 2rem;
        padding-bottom: 2rem;
    overflow: hidden;
    border-bottom:  1px solid #F5F5F5;
}

.sliderSection .sliderHeader{
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-right: -5rem;
  width: 2250px;
}
.sliderSection .sliderHeader button{
border-radius: 8px;
    background: #FFF;
    border: 1px solid #FFF;
    padding: 0rem 2rem;
    /* width: 273px; */
        height: 74px;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    color: rgba(167, 167, 167, 1);
    cursor: pointer;
    transition: .5s ease-in-out;
}
.sliderSection .sliderHeader button:hover {
color: #FFF;
    background: #DE1D3E;
    border: 1px solid #DE1D3E;
}
.sliderSection .sliderHeader  .active{
    color: #FFF;
    background: #DE1D3E;
    border: 1px solid #DE1D3E;
    
        font-weight: 600;
}

.sliderSection .sliderCards{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 3rem;
    padding: 2rem 0;

    /* width: 2400px; */
}

.sliderSection .sliderCards .sliderCard{
width: 363px;
}

.sliderSection .sliderCards .sliderCard img {
    width: 100%;
    height: 230px;
}
.sliderSection .sliderCards .sliderCard p{
    padding: .8rem 0;
    color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
}

.sliderSection .sliderBtns{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.sliderSection .sliderBtns .left{
display: flex;
    align-items: center;
    gap: .5rem;
}
.sliderSection .sliderBtns .left p{
font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #A7A7A7;
}
.sliderSection .sliderBtns .left .btns{
  display: flex;
  align-items: center;
  gap: .3rem;
}
.sliderSection .sliderBtns .left .btns span{
    width: 10px;
    height: 10px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
}

.sliderSection .sliderBtns .left .btns .active {
    background-color: #E61C5D;
}

.sliderSection .sliderBtns .right {
    display: flex;
        align-items: center;
        gap: 1rem;
}
.sliderSection .sliderBtns .right>.button{
    display: flex;
    align-items: center;
    justify-content: center;
     color: rgba(222, 29, 62, 1);
    border: 1px solid rgba(222, 29, 62, 1);
    border-radius: 8px;
    padding: .5rem;
    cursor: pointer;
}

.sliderSection .sliderBtns .right  .active{
    color: #FFF;
        border: 1px solid rgba(222, 29, 62, 1);
        background: #DE1D3E;
}


@media screen and (max-width:450px) {
   
    .sliderSection .sliderHeader {
            width: 1680px;
        }
    .sliderSection .sliderCards .sliderCard {
            width: 343px;
        }
                .sliderSection .sliderHeader button {
                    padding: 0rem 1rem;
                    height: 50px;
                    font-size: 20px;
                }

.sliderSection .sliderCards {
    gap: 2rem;
    padding: 2rem 0.5rem;
}
               
}