.blogs{
    padding-top: 2rem;
        padding-bottom: 3rem;
}

.blogs .searchbox{
    justify-content: center;
}

.blogs .searchbox .search{
border-radius: 6.606px;
    border: 0.826px solid #EEE;
    background: #F6F6F6;
    position: relative;
}
.blogs .searchbox .search .dropbox {
    width: 290.333px;
    /* height: 197px; */
    border-radius: 6px;
    background: #FFF;

    padding: 1rem;

    position: absolute;
    top: 3rem;
    left: 0;
    box-shadow: 0px 1px 5px 0px rgb(71, 66, 66);

    z-index: 1;

}

.blogs .searchbox .search .dropbox .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
        padding: 3px 0;
}

.blogs .searchbox .search .dropbox .category svg {
    color: rgba(222, 29, 62, 1);
}

.blogs .searchbox .search .dropbox .category p {
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.blogs .searchbox .search img{
    width: 16.031px;
        height: 12.977px;

        cursor: pointer;
}


.blogs h3{
    color: #DE1D3E;
        font-size: 18px;
        font-weight: 800;

        margin: 1rem 0;

            display: inline-block;
            /* position: relative; */
}
.blogs h3::after{
    content: '';
        width: 100%;
        height: 2px;
        background: #DE1D3E;
        display: block;
        margin: auto;
        transition: 0.5s;
}

.blogs h3:last-of-type{
    margin-top: 3rem;
}

.blogs .blogcards{
        display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            gap: 1.5rem;
}

.blogs .blogcards .blogcard{
    width: 210px;
    border-radius: 20px;
        background: rgba(255, 185, 0, 0.10);
        box-shadow: 0px 0px 8px 0px #FFF8E5;
        padding: .5rem 1rem;
}


.blogs .blogcards .blogcard .author{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blogs .blogcards .blogcard .author  .authorimg{
    display: flex;
        align-items: center;
        gap: 8px;
}

.blogs .blogcards .blogcard .author .authorimg img{
    width: 35px;
}

.blogs .blogcards .blogcard .author .authorimg span{
    color: #000;
        font-size: 16px;
        font-weight: 500;
}

.blogs .blogcards .blogcard .author .icon{
    /* width: 40.233px; */
    display: flex;
    align-items: center;
    gap: .5rem;
}
.blogs .blogcards .blogcard .author .icon svg:first-child{
    background-color: rgba(196, 196, 196, 0.14);
    color: rgba(0, 0, 0, 1);
}
.blogs .blogcards .blogcard .author .icon svg:last-child {
    background-color: rgba(250, 219, 203, 1);
    color: rgba(251, 0, 9, 1);
}
.blogs .blogcards .blogcard .author .icon svg{
         padding: .2rem;
         font-size: 15px;
         border-radius: 50%;
           cursor: pointer;
         }

.blogs .blogcards .blogcard .blogimg{
    padding: 10px 0;
    position: relative;
}
.blogs .blogcards .blogcard .blogimg img:first-child{
    width: 100%;   
}
.blogs .blogcards .blogcard .blogimg img:last-child {
    width: 31.767px;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.blogs .blogcards .blogcard .blogtext{
    color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
}
.blogs .blogcards .blogcard .blogtext a{
color: #DE1D3E;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

        padding-left: 8px;
}


@media screen and (max-width:850px) {
    .blogs .blogcards .blogcard {
            width: 180px;
}
.blogs .blogcards{
    gap: 1rem;
    justify-content: center;
}
}

@media screen and (max-width: 600px)  {
.blogs .searchbox .search .dropbox{
    width: 210px;
}
}


@media screen and (max-width:500px) {
.blogs .blogcards .blogcard {
        width: 100px;
    }
        .blogs .blogcards .blogcard .author .authorimg img {
            width: 25px;
        }
                .blogs .blogcards .blogcard .author .authorimg span {
                    font-size: 10px;
                }
           .blogs .blogcards .blogcard .author .icon svg {
               font-size: 12px;
           }
                   .blogs .blogcards .blogcard .blogtext {
                       font-size: 10px;
                   }
                                   .blogs .blogcards .blogcard .blogtext a {
                                       font-size: 10px;
                                   }
.blogs .blogcards .blogcard .blogimg img:last-child {
    width: 20px;
    right: .5rem;
}

}

@media screen and (max-width: 430px){
    .blogs .searchbox .search .dropbox {
        width: 181px;
}
} 