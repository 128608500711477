.addSection{
    padding-top: 5rem;
        padding-bottom: 5rem;

        display: flex;
        align-items: center;
        justify-content: center;
}

.addSection img{
    width: 100%;
}