 .container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #0c1421;
  padding: 0rem 4rem;
  background: #e7ebf0;
  height: 100vh;
  position: relative;

}

.container .no-scroll {}

/* .container .left{
  font-size: large;
} */
.container .left h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 15px;
}

.container .left>p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #313957;
  width: 450px;
  margin: 0.5rem 0 0.7rem;
}

.container .left form {
  width: 390px;
}

.container .left form label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-top: 0.5rem;
}

.container .left form input {
  border-radius: 12px;
  border: 1px solid #d4d7e3;
  background: #f7fbff;
  text-indent: 1rem;

  height: 44px;
  width: 100%;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #8897ad;

  margin-bottom: 0.5rem;
}

.container .left form .forget {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #e61c5d;
  margin: 0.5rem 0;
  text-align: end;
}

.container .left form button {
  padding: 12px 0px;
  border-radius: 12px;
  width: 100%;
  background-color: #e61c5d;
  border: 1px solid #e61c5d;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

.container .left form .or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0.5rem 0;
}

.container .left form .or .line {
  /* width: 169.5px; */
  width: 100%;
  height: 1px;
  background: #cfdfe2;
}

.container .left form .or p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #294957;
  text-align: center;
}

.container .left form .socialbtn {
  display: flex;
  padding: 12px 9px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  border-radius: 12px;
  background: #f3f9fa;

  margin-top: 0.4rem;
}

.container .left form .socialbtn p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  color: #313957;
}

.container .left form .link {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #313957;

  text-align: center;

  margin: 0.5rem 0;
}

.container .left form .link a {
  color: #e61c5d;
}

.container .right {
  /* height: 625px; */
  background-color: inherit;
}

.container .right img {
  width: 100%;
  height: 590px;
  border-radius: 20px;
}

@media screen and (max-width: 1050px) {
  .container {
    padding: 0rem 2rem;
  }
}

@media screen and (max-width: 950px) {
  .container {
    justify-content: center;
    /* height: 100vh; */
  }

  .container .right {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .container {
    padding: 0rem 1rem;
  }

  .container .left>p {
    width: auto;
  }
}

@media screen and (max-width: 450px) {

  .container .left form {
    width: auto;
  }
}