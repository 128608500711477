

.experience{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 2rem 1rem;
}
.experience h2{
    color: #DE1D3E;
        font-size: 26.957px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
}


.experience form{
    width: 437px;
}

.experience form>label{
    display: block;
    margin-top: 1rem;

    color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
}

.experience form .box{
    border-radius: 4.291px;
        border: 1px solid #A2A2A2;
margin-top: 1rem;
        padding: 0.5rem 2rem;
}

.experience form .box svg{
    color: #DE1D3E;
}
.experience form .box label{
    color: #999;
        font-size: 15.298px;
        font-style: normal;
        font-weight: 500;
}


.experience button{
    padding: 10px 16px;
    border-radius: 5px;
        background: #DE1D3E;

        color: #FFF;
            font-size: 14.4px;
            font-style: normal;
            font-weight: 600;
            border: none;

            margin-top: 2rem;
            cursor: pointer;
}


@media screen and (max-width:500px) {
        .experience form {
            width: 100%;
        }
}